.App {
  text-align: center;
}

.popup {
  position: sticky;
  bottom: 10px;
  left: 90%;
  cursor: pointer;
  outline: black;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.popup .popuptext {
  visibility: hidden;
  position: absolute;
  bottom: 120%;
  left: -80%;
}
.popup .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}
/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.backImg {
  width: 100%;
  height: 100%;
  opacity: 0.9;
}

.navbarMore {
  top: 0;
  color: white;
  padding-right: 16%;
  font-size: 20px;
}
.container {
  padding: 0;
  display: flex;
  flex-direction: row;
}
.item {
  flex-grow: 1;
  flex-shrink: 0;
  font-size: 2rem;
  line-height: 1;
  text-align: center;
  width: auto;
  padding: 0;
  margin: 5rem;
  display: block;
}
.item-1 {
  flex-grow: 0.5;
}
.item-2 {
  flex-grow: 2;
}

.lastHousesBox {
  margin-bottom: 60px;
}
.lastHouseText {
  margin-top: 50px;
  font-size: 50px;
}
.homePageStyle {
  margin-top: 5%;
  margin-bottom: 5%;
  font-size: 20px;
  align-self: center;
  width: 100%;
}
.homePageStyle > p,
cite {
  font-size: 25px;
}
.filterBox {
  position: sticky;
  top: 94px;
  width: 80%;
  float: right;
  margin-bottom: 40px;
}
.checkboxes {
  margin-left: 60px;
  font-size: 18px;
}
.dropdownBox {
  margin-top: 25px;
  margin-left: 45px;
  font-size: 18px;
}
.filterTextDescription {
  font-size: 15px;
}
.filterText {
  font-size: 18px;
}
.clearFilters {
  color: #17a2b8;
  float: right;
  margin-bottom: 0px;
}
.AddHouse {
  width: 80%;
  height: 100%;
  position: center;
  top: 10%;
  padding-left: 25%;
  margin-bottom: 5%;
  margin-top: 20%;
  margin-left: 5%;
}
.house3Details {
  list-style: none;
  font-size: initial;

}
.houses {
  list-style: none;
  padding-top: 50px;
}
.house {
  list-style: none;
  border-left: 4px solid #008ae6;
  margin-bottom: 40px;
  padding-left: 5px;
}
.houseDetails {
  height:250px;
  min-width: 60%;
  margin-left: -13px;
  border: 1px solid #333333;
}

.block-example {
  margin-bottom: 20px;
}

#carouselExampleIndicators {
  z-index: 0;
}
.FAQ{
  position: center;
  margin-top: 2%;
  margin-left:15%;
  margin-right:15%;
  border-left: 10px solid #008ae6;
  padding-left: 5px;
}

.FAQ > a {
  text-decoration:none ;
  text-decoration-color: #008ae6;
}

.noHouseText {
  font-size: 30px;
  margin: 50px;
}
.house-img {
  height:250px;
  background-position: center;
  background-size: cover;
  position: relative;
}
.carousel-img {
  width: 100%;
  max-height: 650px;
  background-size: cover;
}
.slide-wrapper {
  position: relative;
}
@media (min-width: 768px) {
  .filter-block {
    position: absolute;
    bottom: 0;

    align-content: center;
    width: 100%;
  }
}
.addHouse {
  position: sticky;
  width: 80%;
  height: 100%;
  position: center;
  top: 15%;
  padding-left: 2%;
  margin-bottom: 15%;
  margin-top: 5%;
  margin-left: 10%;
  bottom: 5%;
}

.contactForm {
  padding-left: 20%;
  padding-right: 15%;
}
.dropdownStyle {
  margin-right: 40px;
}
#housetitle {
  font-size: xx-large;
  text-align: center;
  font-weight: 400;
}

#locationInput {
  color: white;
  outline-color: white;
}

#locationbox {
  margin-right: 5px;
}

#minpricebox {
  margin-right: 5px;
}

#maxpricebox {
  margin-right: 5px;
}
.DVtitles {
  border-left: 5px solid #008ae6;
  padding-left: 5px;
  border-bottom: 1px solid #008ae6;
}
#DVimage {
  margin-bottom: 3%;
  margin-top: 3%;
  background-color: #cccccc;
  width: 100%;
  height: 550px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

#minpricefield,
#maxpricefield,
#locationfield,
#locationAC {
  font-size: 19px;
}

#radiogroup {
  margin-top: 0.1%;
  margin-left: 10px;
  margin-right: 10px;
}

#roundedpill {
  margin-bottom: 3.5%;
  height: 100%;
  padding: 1.7% 3.5% 1.1% 4% !important;
  border: 2px solid black;
  background: rgba(255, 255, 255, 0.88);
}

#filterbox {
  width: 49.6%;
  margin-left: 25.15%;
  align-content: center;
  margin-bottom: 1.5%;
}
.containerAdd{ 
  padding: 5%;
  margin: 2% 25% 3% 25%;
  border: 5px solid #008ae6;
  /* padding-left: 5px; */
}

.form {
  line-height: 150% !important;
  font-size: 20px;
}
.form > row {
  display: flex1;
}

#locationAC {
  padding-top: 0.8% !important;
}

#minpricefield,
#maxpricefield {
  padding-top: 6% !important;
}

#btnDiv {
  margin-top: -0.3%;
}

.filterboxForm{
  overflow:scroll;
  overflow-x:hidden;
  max-height: 600px;
}

#houseDesc{
  margin-left: 5px;
  margin-right: 15px;
  font-size: 16px;
  overflow: hidden; 
  text-overflow: ellipsis; 
  display: -webkit-box; 
  line-height: 22px;  
  max-height: 44px;      
  /* The number of lines to be displayed */ 
  -webkit-line-clamp: 2;  
  -webkit-box-orient: vertical; 
}
.filterTextDescription{
  font-size: medium;
}
#lastHousesImg{  
  width: 100%;
  height: 250px;
  background-position: center;
  background-size: cover;
}

#navbar,#footer,#copyrightText{
  background-color: #303030;
}

#rowDiv{
  width: 100% !important;
}

 .btn-default{
 background-color :#008ae6 !important;
}

/* #buttons1{
  background-color: #008ae6 !important;
} */

#submitBtn, #addBtnForm, #reloadBtn{
  background-color :#008ae6 !important;
  color: white !important;
  margin-top: 6%;
  margin-bottom: 10px;
}

#submitBtn{
  margin-left: 25%;
}

.dealer-content{
  background-color: #4d4d4d;
  margin-bottom: 5%;
}
.property-title{
  text-align: center;
  color: white;
  margin-top: 1%;
  border-bottom: 1px solid #008ae6;
}

.widgets{
  font-size: 25px;
  margin-bottom: 2%;
  color: white;
}
#DVicons{
  color: #008ae6;
}
.property-info-label{
  font-size: large;
}
.property-info-value{
  font-size: x-large;
}

.filterTextDescription{
 padding-top: 7px; 
}

#about{
  padding-left: 3.2%;
}

#cardTitle{
  margin-left: 15.4%;
}

#accordionExample{
  margin-top: 4%;
  margin-bottom: 4%;
}