.sc-message {
  width: 300px;
  margin: auto;
  padding-bottom: 10px;
  display: flex;
}

.sc-message--content {
  width: 100%;
  display: flex;
}

.sc-message--content.sent {
  justify-content: flex-end;
}

.sc-message--content.sent .sc-message--avatar {
  margin-right: 0px;
  margin-left: 15px;
}

.sc-message--avatar {
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  align-self: center;
  margin-right: 15px;
}

.sc-message--text {
  padding: 17px 20px;
  border-radius: 6px;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
  white-space: pre-wrap;
  -webkit-font-smoothing: subpixel-antialiased;
  word-wrap: break-word;
  width: calc(100% - 90px);
}

.sc-message--content.sent .sc-message--text {
  color: black;
  background-color: #008AE6; 
  max-width: calc(100% - 120px);
  word-wrap: break-word;
}

.sc-message--content.received .sc-message--text {
  color: #263238;
  background-color: #f4f7f9;
}

@media (max-width: 450px) {
  .sc-message {
    width: 80%;
  }
}